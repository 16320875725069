import { useEffect, useState } from "react";
import axios from "axios";
import SideBar from "../component/SideBar";
import TopBar from "../component/TopBar";
import React from "react";
import { SendIcon } from "../svg";
import { Button } from "../components/ui/button";
import { cn } from "../lib/utils";
import { Calendar } from "../components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import { Calendar as CalendarIcon } from "lucide-react";
import { addDays, format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";

// import Select from "react-select";
// import { format } from "date-fns";
// import { Calendar as CalendarIcon } from "lucide-react";
// import { Calendar } from "../components/ui/calendar";
// import {
//   Popover,
//   PopoverContent,
//   PopoverTrigger,
// } from "../components/ui/popover";
// import { cn } from "../lib/utils";

const ReportUserAganistWorkflows = ({
  setUser,
  propsUsername,
  nameForDisplay,
  faq,
  reportPerCategory,
  reportPerUser,
  reportAdvanced,
  allTicAcc,
  myGroupTicAcc,
  dashboardAcc,
  myTicAcc,
  unreadNot,
  notificationRefresh,
  setNotificationRefresh,
  notificationData,
  setNotificationCount,
  sideBarReport,
  setSideBarReport,
  sideBarData,
  createdByMeTickets,
  workflowReport,
  userWorkflowReport,
  dragAndDrop,
  myGroups,
  setMyTeamTicketsToggle,
  setSelectedWf,
  selectedWf,
  myTeamTicketsToggle,
  settings,
  myGroupTicketCount,
}) => {
  const URL = process.env.REACT_APP_ENVIRONMENT;
  const token = localStorage.getItem("access");

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [workflowsFirstData, setWorkflowsFirstData] = useState([]);
  const [date, setDate] = useState();
  const [loadingText, setLoadingText] = useState("loadingText");
  const [usernameClass, setusernameClass] = useState("false");
  const [isLoading, setIsLoading] = useState(false);

  const getReport = () => {
    setusernameClass("false");
    setData([]);
    setLoadingText("loadingText");
    axios
      .get(`${URL}/api/tickets/report/users/workflow_done/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          start_date: date?.from,
          end_date: date?.to,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setWorkflowsFirstData(res.data.data[0].workflow_list);
        setusernameClass("");
        setLoadingText("false");
      })
      .catch(() => {});
  };

  const getReportExport = () => {
    setIsLoading(true);
    axios
      .get(`${URL}/api/tickets/report/users/workflow_done/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          as_xls: true,
          start_date: date?.from,
          end_date: date?.to,
        },
      })
      .then((res) => {
        setIsLoading(false);
        window.open(`${res.data.data.url}`, "_blank", "noopener,noreferrer");
      })
      .catch(() => {});
  };

  const sideBarCheck = () => {
    if (sideBarData.length !== 0) {
      if (
        sideBarData?.some(
          (item) => item.label === "REPORT_PER_USER_AGAINST_WORKFLOWS"
        )
      ) {
      } else {
        navigate("/*");
      }
    }
  };

  useEffect(() => {
    getReport();
  }, [date]);

  useEffect(() => {
    sideBarCheck();
  }, [sideBarData]);

  return (
    <div>
      <SideBar
        myGroups={myGroups}
        setMyTeamTicketsToggle={setMyTeamTicketsToggle}
        myTeamTicketsToggle={myTeamTicketsToggle}
        setSelectedWf={setSelectedWf}
        selectedWf={selectedWf}
        settings={settings}
        createdByMeTickets={createdByMeTickets}
        userWorkflowReportClass={"userWorkflowReport"}
        faq={faq}
        reportPerCategory={reportPerCategory}
        reportPerUser={reportPerUser}
        reportAdvanced={reportAdvanced}
        allTicAcc={allTicAcc}
        myGroupTicAcc={myGroupTicAcc}
        dashboardAcc={dashboardAcc}
        myTicAcc={myTicAcc}
        setSideBarReport={setSideBarReport}
        sideBarReport={sideBarReport}
        myGroupTicketCount={myGroupTicketCount}
        workflowReport={workflowReport}
        userWorkflowReport={userWorkflowReport}
        dragAndDrop={dragAndDrop}
      />
      <TopBar
        setUser={setUser}
        username={propsUsername}
        nameForDisplay={nameForDisplay}
        unreadNot={unreadNot}
        notificationRefresh={notificationRefresh}
        setNotificationRefresh={setNotificationRefresh}
        notificationData={notificationData}
        setNotificationCount={setNotificationCount}
      />
      <div className="reportsTT">
        <div className="reports">
          <div className="reportsTitle">
            <h2>İstifadəçilərə Görə Workflow Report</h2>
            <div className="reportsTitleR">
              <div className="dateFilterFirst">
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      id="date"
                      variant={"calendar"}
                      className={cn(
                        "w-[240px] justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {date?.from ? (
                        date.to ? (
                          <>
                            {format(date.from, "LLL dd, y")} -{" "}
                            {format(date.to, "LLL dd, y")}
                          </>
                        ) : (
                          format(date.from, "LLL dd, y")
                        )
                      ) : (
                        <span className="calendarText">Tarix</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      initialFocus
                      mode="range"
                      defaultMonth={date?.from}
                      selected={date}
                      onSelect={setDate}
                      numberOfMonths={2}
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <div className="dateFilterFirst">
                <span>
                  <Button
                    className={cn("w-[105px]")}
                    onClick={getReportExport}
                    disabled={isLoading}
                    variant="mybtn2"
                  >
                    {isLoading ? <div></div> : <SendIcon />}

                    <span className="newTicketText">
                      {isLoading ? (
                        <div className="flex items-center gap-2">
                          Yüklənir
                          <div className="spinner"></div>
                        </div>
                      ) : (
                        "Export"
                      )}
                    </span>
                  </Button>
                </span>
              </div>
            </div>
          </div>
          <div className="dateFilterSecond">
            <span>
              <Button
                className="w-full ml-0"
                disabled={isLoading}
                onClick={getReportExport}
                variant="mybtn2"
              >
                {isLoading ? <div></div> : <SendIcon />}

                <span className="newTicketText">
                  {isLoading ? (
                    <div className="flex items-center gap-2">
                      Yüklənir
                      <div className="spinner"></div>
                    </div>
                  ) : (
                    "Export"
                  )}
                </span>
              </Button>
            </span>
          </div>
          <div className="dateFilterSecond">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  id="date"
                  variant={"calendar"}
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !date && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date?.from ? (
                    date.to ? (
                      <>
                        {format(date.from, "LLL dd, y")} -{" "}
                        {format(date.to, "LLL dd, y")}
                      </>
                    ) : (
                      format(date.from, "LLL dd, y")
                    )
                  ) : (
                    <span className="calendarText">Tarix</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  initialFocus
                  mode="range"
                  defaultMonth={date?.from}
                  selected={date}
                  onSelect={setDate}
                  numberOfMonths={2}
                />
              </PopoverContent>
            </Popover>
          </div>
          <span className={loadingText}>Yüklənir...</span>
          <div className="customersParent">
            <table id="customers">
              <thead>
                <tr>
                  <th className={usernameClass}>Istifadəçi adı </th>
                  {workflowsFirstData.map((item, index) => {
                    return (
                      <th className={usernameClass} key={index}>
                        {item?.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map(({ user, workflow_list }) => (
                  <tr key={user.id}>
                    <td>
                      {user?.full_name.length === 0
                        ? user?.username
                        : user?.full_name}
                    </td>
                    {workflow_list.map((workflow, index) => (
                      <td key={index}>{workflow.count}</td>
                    ))}
                  </tr>
                ))}
                {/* {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.user?.username}</td>
                    </tr>
                  );
                })} */}
                {/* {data.map(({ user, workflow_list }) => (
                  <tr key={user.id}>
                    <td>{user.full_name}</td>
                    {workflow_list.map((workflow, index) => (
                      <td key={index}>
                        {workflow.count}
                      </td>
                    ))}
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportUserAganistWorkflows;
