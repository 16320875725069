import { useEffect, useState } from "react";
import axios from "axios";
import SideBar from "../component/SideBar";
import TopBar from "../component/TopBar";
import React from "react";
import { useNavigate } from "react-router-dom";
import Column from "../component/DragAndDropColumn";
import { DragDropContext } from "react-beautiful-dnd";

const DragAndDrop = ({
  setUser,
  propsUsername,
  nameForDisplay,
  faq,
  reportPerCategory,
  reportPerUser,
  reportAdvanced,
  allTicAcc,
  myGroupTicAcc,
  dashboardAcc,
  myTicAcc,
  unreadNot,
  notificationRefresh,
  setNotificationRefresh,
  notificationData,
  setNotificationCount,
  sideBarReport,
  setSideBarReport,
  createdByMeTickets,
  sideBarData,
  workflowReport,
  userWorkflowReport,
  dragAndDrop,
  myGroups,
  setSelectedWf,
  selectedWf,
  setMyTeamTicketsToggle,
  myTeamTicketsToggle,
  settings,
  myGroupTicketCount,
}) => {
  const URL = process.env.REACT_APP_ENVIRONMENT;
  const token = localStorage.getItem("access");

  const [completed, setCompleted] = useState([]);
  const [inProgress, setInProgress] = useState([]);

  const [incomplete, setIncomplete] = useState([]);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    axios(`${URL}/api/tickets/mine`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page_size: 30,
      },
    }).then((json) => {
      setCompleted(
        json.data.data.results.filter(
          (task) => task.current_stage.label === "done"
        )
      );
      setIncomplete(
        json.data.data.results.filter(
          (task) => task.current_stage.label === "Start"
        )
      );
      setInProgress(
        json.data.data.results.filter(
          (task) => task.current_stage.label === "in progress"
        )
      );
    });
  }, [refresh]);

  const handleDragEnd = (result) => {
    axios
      .post(
        `${URL}/api/tickets/${result.draggableId}/move_next_stage/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((json) => {
        setRefresh(!refresh);
      });

    const { destination, source, draggableId } = result;

    if (!destination || source.droppableId === destination.droppableId) return;

    deletePreviousState(source.droppableId, draggableId);

    const task = findItemById(draggableId, [
      ...incomplete,
      ...completed,
      ...inProgress,
    ]);

    setNewState(destination.droppableId, task);
  };

  function deletePreviousState(sourceDroppableId, taskId) {
    switch (sourceDroppableId) {
      case "1":
        setIncomplete(removeItemById(taskId, incomplete));
        break;
      case "2":
        setInProgress(removeItemById(taskId, inProgress));
        break;
      case "3":
        setCompleted(removeItemById(taskId, completed));
        break;
    }
  }
  function setNewState(destinationDroppableId, task) {
    let updatedTask;
    switch (destinationDroppableId) {
      case "1": // TO DO
        updatedTask = { ...task, completed: false };
        setIncomplete([updatedTask, ...incomplete]);
        break;
      case "2": // DONE
        updatedTask = { ...task, completed: false };
        setInProgress([updatedTask, ...inProgress]);
        break;
      case "3": // INPROGRESS
        updatedTask = { ...task, completed: true };
        setCompleted([updatedTask, ...completed]);
        break;
    }
  }
  function findItemById(id, array) {
    return array.find((item) => item.id == id);
  }

  function removeItemById(id, array) {
    return array.filter((item) => item.id != id);
  }

  const navigate = useNavigate();

  const sideBarCheck = () => {
    if (sideBarData.length !== 0) {
      if (sideBarData?.some((item) => item.label === "DRAG_AND_DROP")) {
      } else {
        navigate("/*");
      }
    }
  };
  useEffect(() => {
    sideBarCheck();
  }, [sideBarData]);

  return (
    <div>
      <SideBar
        myGroups={myGroups}
        setMyTeamTicketsToggle={setMyTeamTicketsToggle}
        myTeamTicketsToggle={myTeamTicketsToggle}
        setSelectedWf={setSelectedWf}
        selectedWf={selectedWf}
        settings={settings}
        createdByMeTickets={createdByMeTickets}
        propsDragAndDrop={"visitDragAndDrop"}
        faq={faq}
        reportPerCategory={reportPerCategory}
        reportPerUser={reportPerUser}
        reportAdvanced={reportAdvanced}
        allTicAcc={allTicAcc}
        myGroupTicAcc={myGroupTicAcc}
        dashboardAcc={dashboardAcc}
        myTicAcc={myTicAcc}
        setSideBarReport={setSideBarReport}
        sideBarReport={sideBarReport}
        myGroupTicketCount={myGroupTicketCount}
        workflowReport={workflowReport}
        userWorkflowReport={userWorkflowReport}
        dragAndDrop={dragAndDrop}
      />
      <TopBar
        setUser={setUser}
        username={propsUsername}
        nameForDisplay={nameForDisplay}
        unreadNot={unreadNot}
        notificationRefresh={notificationRefresh}
        setNotificationRefresh={setNotificationRefresh}
        notificationData={notificationData}
        setNotificationCount={setNotificationCount}
      />
      <div className="reportsTT">
        <DragDropContext onDragEnd={handleDragEnd}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "row",
              margin: "0 auto",
            }}
          >
            <Column title={"START"} tasks={incomplete} id={"1"} />
            <hr
              style={{
                height: "1000px",
                width: "1px",
                backgroundColor: "#E4E4E7",
              }}
            />
            <Column title={"INPROGRESS"} tasks={inProgress} id={"2"} />
            <hr
              style={{
                height: "1000px",
                width: "1px",
                backgroundColor: "#E4E4E7",
              }}
            />
            <Column title={"DONE"} tasks={completed} id={"3"} />
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default DragAndDrop;
