import { BgLines } from "../svg";
import { useNavigate } from "react-router-dom";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Label } from "../components/ui/label";
import { useState } from "react";
import axios from "axios";

const Login = ({ setUser, permission }) => {
  const URL = process.env.REACT_APP_ENVIRONMENT;

  const something = (event) => {
    if (event.keyCode === 13) {
      sendLogin(permission);
    }
  };

  const navigate = useNavigate();
  const [errormessage, setErrormessage] = useState("");

  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const sendLogin = (permission) => {
    setErrormessage("");
    axios
      .post(`${URL}/api/token/`, loginData)
      .then((res) => {
        if ((res.status = 200)) {
          localStorage.setItem("username", loginData.username);
          axios
            .get(`${URL}/api/auth/me/permissions/`, {
              headers: {
                Authorization: `Bearer ${res.data.data.access}`,
              },
            })
            .then((res) => {
              permission(res?.data?.data?.can_add_ticket);
            });

          setUser(true);
          navigate("/dashboard");
          localStorage.setItem("access", res?.data?.data?.access);
          localStorage.setItem("refresh", res?.data?.data?.refresh);
        }
      })
      .catch((error) => {
        if (error?.response?.request?.status !== 200) {
          setErrormessage("Login və ya şifrə yanlışdır!");
        }
      });
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setLoginData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  return (
    <div className="loginPage">
      <img
        className="bgImg"
        src={require("../style/bg-images/loginBG.png")}
        alt=""
      />
      {/* <div className="bgLines"> */}
      <BgLines className="bgLines" />
      {/* </div> */}
      <div className="loginFormTT">
        <div className="loginForm">
          <img
            className="loginFormBG"
            src={require("../style/bg-images/sideBarLogo.png")}
            alt=""
          />
          <div className="loginInputs">
            <h2>Tiket sisteminə giriş.</h2>
            <div className="w-full">
              <div className="mb-1">
                <Label>Istifadəçi adı</Label>
              </div>
              <Input
                onChange={handleChange}
                onKeyDown={(e) => something(e)}
                name="username"
                type="email"
                // placeholder="mail@omnitech.az"
                placeholder="Istifadəçi adı"
              />
            </div>
            <div className="w-full mt-8 mb-4">
              <div className="mb-1">
                <Label>Şifrə</Label>
              </div>
              <Input
                onChange={handleChange}
                onKeyDown={(e) => something(e)}
                name="password"
                type="password"
                placeholder="Parol"
              />
            </div>
            <span className="errormessage">{errormessage}</span>
            <div className="loginBtn">
              <Button
                onClick={() => {
                  sendLogin(permission);
                }}
                variant="mybtn"
                width="400px"
              >
                <Label>Daxil ol</Label>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
