import React, { useRef, useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import SideBar from "../component/SideBar";
import TopBar from "../component/TopBar";
import Ticket from "../component/Ticket";
import AnniouncmentCard from "../component/AnnouncmentCard";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
// import Select from "react-select";

import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogTrigger,
} from "../components/ui/dialog";

import axios from "axios";
import { Pin } from "lucide-react";
import { Checkbox } from "../components/ui/checkbox";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Label } from "../components/ui/label";
import { DialogCloseButton } from "../component/Alert";
import {
  ArrowDown,
  ArrowUp,
  CategoriesArrow,
  CircleForCategorie,
  RightArrowForNewTicket,
  SearchI,
  TicketSearchCantFind,
  TicketSearchForFind,
} from "../svg";
import { useNavigate } from "react-router-dom";
const Dashboard = ({
  setUser,
  propsUsername,
  nameForDisplay,
  ticketAddPermission,
  faq,
  reportPerCategory,
  reportPerUser,
  reportAdvanced,
  allTicAcc,
  myGroupTicAcc,
  dashboardAcc,
  myTicAcc,
  unreadNot,
  notificationRefresh,
  setNotificationRefresh,
  notificationData,
  setNotificationCount,
  sideBarReport,
  setSideBarReport,
  createdByMeTickets,
  workflowReport,
  usernameID,
  userWorkflowReport,
  dragAndDrop,
  myGroups,
  setSelectedWf,
  userGroupsData,
  selectedWf,
  sideBarData,
  setMyTeamTicketsToggle,
  myTeamTicketsToggle,
  settings,
  myGroupTicketCount,
}) => {
  const token = localStorage.getItem("access");
  const URL = process.env.REACT_APP_ENVIRONMENT;

  const navigate = useNavigate();

  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [announcmentData, setAnnouncmentData] = useState([]);
  const [isInitialAssignee, setIsInitialAssignee] = useState(false);
  const [assignedOpen, setAssignedOpen] = useState({});
  const [newTicketCategories, setNewTicketCategories] = useState();
  const [dataCategories, setDataCategories] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [ticketFinder, setTicketFinder] = useState(null);
  const [finderFunc, setFinderFunc] = useState(false);
  const [groupsData, setGroupsData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState([]);
  const [announcmentClassname, setAnnouncmentClassname] = useState("false");

  const [dependenceForDebounce, setDependenceForDebounce] = useState(false);

  const [requestTypeData, setRequestTypeData] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [selectedKeywordData, setSelectedKeywordData] = useState(3);

  const [ticketCategoriesPlaceholder, setTicketCategoriesPlaceholder] =
    useState("Kategoriya seçin");

  const [paginition, setPaginition] = useState("false");
  const [prevPage, setPrevPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageClass, setCurrentPageClass] = useState("false");
  const [nextClass, setNextClass] = useState("");
  const [prevClass, setPrevClass] = useState("false");
  const [firstPagClass, setFirstPagClass] = useState("blackNumBtn");
  const [lastPagClass, setLastPagClass] = useState("secondary");
  const [allPagClass, setAllPagClass] = useState("secondary");
  const [currentPagVariant, setCurrentPagVariant] = useState("secondary");
  const [totalPageClass, setTotalPageClass] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [categorieArrows, setCategorieArrows] = useState("false");

  const [mappedState, setMappedState] = useState([]);
  const [error, setError] = useState({
    open: false,
    title: null,
    description: null,
    success: null,
  });

  const [assigneeForTicketPost, setAssigneeForTicketPost] = useState({
    assignee: "",
  });

  const [value, setValue] = useState("");

  const [query, setQuery] = useState("");
  const [cancelToken, setCancelToken] = useState(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (cancelToken) {
        cancelToken.cancel();
      }

      const source = axios.CancelToken.source();
      setCancelToken(source);
      if (dependenceForDebounce === true) {
        axios(`${URL}/api/categories/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page_size: 9999999,
            search: query,
            keyword_id: selectedKeywordData,
            ordering: "name",
          },
        })
          .then((res) => {
            setDataCategories(res.data.data.results);
          })
          .catch(() => {});
      }
    }, 700);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [query]);

  const [initialFormData, setInitialFormData] = useState(
    requestTypeData.map((field) => ({
      field_definition: field.id,
      value: "",
    }))
  );

  const [ticketData, setTicketData] = useState(initialFormData);
  const [descriptionValue, setDescriptionValue] = useState("");

  const searchWithEnter = (event) => {
    if (event.keyCode === 13) {
      getTickets();
    }
  };

  const dates = new Date();
  let days = dates.getDate();
  let months = dates.getMonth() + 1;
  let years = dates.getFullYear();
  let currentDate = `${days}/${months}/${years}`;

  const handleClick = () => {
    setIsInitialAssignee(false);
    setNewTicketCategories();
  };

  const handleClear = () => {
    setTicketData(initialFormData);
  };

  const ticketPost = () => {
    const filteredData = ticketData.filter((field) => field.value !== "");
    axios
      .post(
        `${URL}/api/tickets/`,
        {
          fields: filteredData.length > 0 ? filteredData : [],
          current_category: newTicketCategories,
          // fields: [...ticketData],
          assignee: assigneeForTicketPost.assignee,
          description: descriptionValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDescriptionValue("");
        handleClear();
        assigneeForTicketPost.assignee = "";
        setIsInitialAssignee(false);
        setRequestTypeData([]);
        setNewTicketCategories();
        setError({
          open: true,
          title: "Successfully accepted!",
          description: res?.data.success_message,
          success: res?.data.success,
        });
        setAssignedOpen({
          ticket: res?.data.data,
          isAssignedMe: res?.data.data.assignee?.id === usernameID,
        });
        setRefresh(!refresh);
        setTicketCategoriesPlaceholder("Kategoriya seçin");
        handleClick();
        setData([]);
        setValue("");
        setTicketFinder(null);
        setQuery("");
      })
      .catch((res) => {
        newTicketCategories > 0
          ? setButtonDisabled(false)
          : setButtonDisabled(true);
        if (res?.response?.status === 400) {
          setError({
            open: true,
            title: "Error message",
            description: res?.response?.data?.data?.error,
            success: res?.response?.data?.success,
          });
        }
      });
  };

  const handleChange = (e, field_definition) => {
    const { value } = e.target;
    setTicketData((prevData) =>
      prevData.map((field) =>
        field.field_definition === field_definition
          ? { ...field, value }
          : field
      )
    );
  };

  const getKeywords = () => {
    axios
      .get(`${URL}/api/keyword/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setKeywordsData(res.data.data.results);
      })
      .catch(() => {});
  };

  const getGroups = () => {
    axios
      .get(`${URL}/api/auth/group/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page_size: 999,
        },
      })
      .then((res) => {
        setGroupsData(res.data.data.results);
      })
      .catch(() => {});
  };

  const convertedState = mappedState.reduce((acc, current) => {
    const key = Object.keys(current)[0];
    acc[key] = current[key];
    return acc;
  }, {});

  const getTickets = async (props) => {
    setData([]);
    // setButtonDisabled(
    //   ticketAddPermission === true && value.trim().length > 8 ? false : true
    // );
    // if (
    //   value?.length +
    //     ticketData?.cashier_id?.length +
    //     ticketData?.voen?.length !==
    //   0
    // ) {
    let paginition = props > 0 ? `?page=${props}` : "";

    axios(`${URL}/api/tickets/${paginition}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: convertedState,
      // params: {
      //  convertedState,
      // page_size: 9,
      // page: 4,
      // cashier_id: ticketData.cashier_id,
      // voen: ticketData.voen,
      // phone_number: value.trim(),
      // },
    })
      .then((res) => {
        setData(res.data.data.results);
        res.data.data.count === 0
          ? setTicketFinder(false)
          : setTicketFinder(true);
        if (res.data.data.next?.split("page=")[1].indexOf("&") === -1) {
          setNextPage(res.data.data.next?.split("page=")[1]);
        } else {
          setNextPage(
            res.data.data.next
              ?.split("page=")[1]
              .substring(0, res.data.data.next?.split("page=")[1].indexOf("&"))
          );
        }
        setCurrentPagVariant("blackNumBtn");
        setAllPagClass("secondary");

        if (
          res.data.data.previous?.split("page=")[1] === undefined ||
          res.data.data.previous
            ?.split("page=")[1]
            .substring(
              0,
              res.data.data.previous?.split("page=")[1].indexOf("&")
            ) === undefined
        ) {
          setPrevPage(2);
        } else {
          if (res.data.data.previous?.split("page=")[1].indexOf("&") === -1) {
            setPrevPage(res.data.data.previous?.split("page=")[1]);
          } else {
            setPrevPage(
              res.data.data.previous
                ?.split("page=")[1]
                .substring(
                  0,
                  res.data.data.previous?.split("page=")[1].indexOf("&")
                )
            );
          }
        }

        setCurrentPage(res.data.data.current_page);
        setLastPage(res.data.data.total_pages);

        res.data.data.total_pages === 1
          ? setPaginition("false")
          : setPaginition("ticketsPaginition");

        res.data.data.total_pages === 1
          ? setTotalPageClass("false")
          : setTotalPageClass("");

        res.data.data.current_page === 1
          ? setFirstPagClass("blackNumBtn")
          : setFirstPagClass("secondary");

        res.data.data.current_page === res.data.data.total_pages
          ? setLastPagClass("blackNumBtn")
          : setLastPagClass("secondary");

        res.data.data.current_page > 2
          ? setPrevClass("")
          : setPrevClass("false");

        if (
          res.data.data.current_page === res.data.data.total_pages - 1 ||
          res.data.data.total_pages === res.data.data.current_page
        ) {
          setNextClass("false");
        } else {
          setNextClass("");
        }

        if (
          res.data.data.current_page === 1 ||
          res.data.data.current_page === res.data.data.total_pages
        ) {
          setCurrentPageClass("false");
        } else {
          setCurrentPageClass("");
        }
      })
      .catch(() => {});
    // }
  };

  const getCategories = (props) => {
    axios(`${URL}/api/categories/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page_size: 9999999,
        search: query,
        keyword_id: selectedKeywordData,
        user_group: props,
        ordering: "name",
      },
    })
      .then((res) => {
        setDataCategories(res?.data?.data?.results);
      })
      .catch(() => {});
  };

  const getAnnouncment = () => {
    axios(`${URL}/api/announcement/list/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page_size: 99,
      },
    })
      .then((res) => {
        res.data.data.results.length === 0
          ? setAnnouncmentClassname("false")
          : setAnnouncmentClassname("announcments");
        setAnnouncmentData(res.data.data.results);
      })
      .catch(() => {});
  };

  const sideBarCheck = () => {
    if (sideBarData.length !== 0) {
      if (sideBarData?.some((item) => item.label === "DASHBOARD")) {
      } else {
        navigate("/*");
      }
    }
  };

  useEffect(() => {
    const mapped = requestTypeData.map((item) => ({
      [item.filter_with]: "",
    }));
    setMappedState(mapped);
  }, [requestTypeData]);

  const handleInputChange = (fieldName, value) => {
    const updatedState = mappedState.map((item) => {
      if (fieldName in item) {
        return {
          [fieldName]: value,
        };
      }
      return item;
    });
    setMappedState(updatedState);
  };

  useEffect(() => {
    sideBarCheck();
  }, [sideBarData]);

  useEffect(() => {
    getAnnouncment();
    getGroups();
    getKeywords();
  }, []);

  useEffect(() => {
    newTicketCategories > 0 && ticketAddPermission === true
      ? setButtonDisabled(false)
      : setButtonDisabled(true);
  }, [ticketAddPermission, newTicketCategories]);

  useEffect(() => {
    getCategories();
  }, [selectedKeywordData]);

  return (
    <>
      {assignedOpen.isAssignedMe
        ? [assignedOpen.ticket].map((item) => {
            const datee = new Date(item.created_at).toLocaleDateString();
            const dateee = new Date(item.created_at);
            const hours = dateee.getHours();
            const minutes = dateee.getMinutes();
            const seconds = dateee.getSeconds();
            const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
              minutes < 10 ? "0" : ""
            }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

            const dateCompleted = new Date(
              item?.completed_at
            ).toLocaleDateString();
            const dateCompleted2 = new Date(item?.completed_at);
            const hours2 = dateCompleted2.getHours();
            const minutes2 = dateCompleted2.getMinutes();
            const seconds2 = dateCompleted2.getSeconds();
            const formattedTime2 = `${hours2 < 10 ? "0" : ""}${hours2}:${
              minutes2 < 10 ? "0" : ""
            }${minutes2}:${seconds2 < 10 ? "0" : ""}${seconds2}`;
            return (
              <Ticket
                assignedMe
                setAssignedOpen={setAssignedOpen}
                userGroupsData={userGroupsData}
                ticketLabel={item?.current_workflow?.name}
                key={item.id}
                id={item.id}
                customFields={item?.fields}
                requestTypeFields={item?.current_category?.request_type?.fields}
                ticketNum={"Tiket# " + item.id}
                customerName={item.title}
                comment={item.description}
                setStateRefresh={setRefresh}
                completedAt={item.completed_at != null ? "false" : ""}
                ifAssigneMePost={
                  item?.assignee?.username === localStorage.getItem("username")
                    ? ""
                    : "false"
                }
                stateRefresh={refresh}
                background={
                  item?.assignee === null
                    ? "ticket22"
                    : item.completed_at != null
                    ? "ticketEnd"
                    : "ticketInProgress"
                }
                state={item.current_stage}
                createdBy={
                  item?.created_by?.full_name.length === 0
                    ? item.created_by.username
                    : item?.created_by?.full_name
                }
                ownerGroup={item.owner_group?.name}
                usernameID={usernameID}
                ticketAssigneID={item?.assignee?.id}
                category={item.current_category.name}
                icraci={item.assignee ? "icraçı" : ""}
                assigned={
                  item.assignee
                    ? item?.assignee?.full_name.length === 0
                      ? item?.assignee?.username
                      : item?.assignee?.full_name
                    : "İcraçı təyin edilməyib"
                }
                date={datee}
                time={formattedTime}
                dateCompleted={
                  item.completed_at === null
                    ? ""
                    : `Tamamlandı:${dateCompleted}`
                }
                timeCompleted={item.completed_at === null ? "" : formattedTime2}
                phoneNum={item.phone_number}
                cashier_id={item?.cashier_id}
                voen={item?.voen}
              />
            );
          })
        : null}
      <div>
        {error.open ? (
          <DialogCloseButton setError={setError} error={error} />
        ) : null}
        <SideBar
          myGroups={myGroups}
          setMyTeamTicketsToggle={setMyTeamTicketsToggle}
          myTeamTicketsToggle={myTeamTicketsToggle}
          setSelectedWf={setSelectedWf}
          selectedWf={selectedWf}
          settings={settings}
          dashboard={"dashboardSideBar"}
          createdByMeTickets={createdByMeTickets}
          faq={faq}
          reportPerCategory={reportPerCategory}
          reportPerUser={reportPerUser}
          reportAdvanced={reportAdvanced}
          allTicAcc={allTicAcc}
          myGroupTicAcc={myGroupTicAcc}
          dashboardAcc={dashboardAcc}
          myTicAcc={myTicAcc}
          setSideBarReport={setSideBarReport}
          sideBarReport={sideBarReport}
          myGroupTicketCount={myGroupTicketCount}
          workflowReport={workflowReport}
          userWorkflowReport={userWorkflowReport}
          dragAndDrop={dragAndDrop}
        />
        <TopBar
          setUser={setUser}
          username={propsUsername}
          nameForDisplay={nameForDisplay}
          unreadNot={unreadNot}
          notificationRefresh={notificationRefresh}
          setNotificationRefresh={setNotificationRefresh}
          notificationData={notificationData}
          setNotificationCount={setNotificationCount}
        />
        <div className="dashboard">
          <div className="dashboardContent">
            <div
              onClick={() => {
                const scrollHeight = document.documentElement.scrollHeight;
                const clientHeight = document.documentElement.clientHeight;
                const scrollPosition = scrollHeight - clientHeight;
                window.scrollTo({
                  top: scrollPosition * 1,
                  behavior: "smooth",
                });
              }}
              className="dashboardNewTicketSection"
            >
              <span>Yeni tiket</span>
              <RightArrowForNewTicket />
            </div>
            <div className="dashboardLeft">
              <div className={announcmentClassname}>
                {announcmentData.map((item) => {
                  return (
                    <AnniouncmentCard
                      key={item.id}
                      title={item.title}
                      level={item.level}
                      content={item.content}
                    />
                  );
                })}
              </div>
              <div className="dashboardTicketAreaTT">
                <div className="dashboardTicketArea">
                  <div className="w-full flex justify-center items-center">
                    {ticketFinder === null ? (
                      <TicketSearchForFind />
                    ) : ticketFinder === false ? (
                      <TicketSearchCantFind />
                    ) : null}
                  </div>
                  {ticketFinder === true
                    ? data.map((item) => {
                        const datee = new Date(
                          item.created_at
                        ).toLocaleDateString();
                        const dateee = new Date(item.created_at);
                        const hours = dateee.getHours();
                        const minutes = dateee.getMinutes();
                        const seconds = dateee.getSeconds();
                        const formattedTime = `${
                          hours < 10 ? "0" : ""
                        }${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
                          seconds < 10 ? "0" : ""
                        }${seconds}`;

                        const dateCompleted = new Date(
                          item?.completed_at
                        ).toLocaleDateString();
                        const dateCompleted2 = new Date(item?.completed_at);
                        const hours2 = dateCompleted2.getHours();
                        const minutes2 = dateCompleted2.getMinutes();
                        const seconds2 = dateCompleted2.getSeconds();
                        const formattedTime2 = `${
                          hours2 < 10 ? "0" : ""
                        }${hours2}:${minutes2 < 10 ? "0" : ""}${minutes2}:${
                          seconds2 < 10 ? "0" : ""
                        }${seconds2}`;
                        return (
                          <Ticket
                            key={item.id}
                            id={item.id}
                            customFields={item?.fields}
                            userGroupsData={userGroupsData}
                            requestTypeFields={
                              item?.current_category?.request_type?.fields
                            }
                            ticketLabel={item?.current_workflow?.name}
                            ticketNum={"Tiket# " + item.id}
                            customerName={item.title}
                            comment={item.description}
                            setStateRefresh={setRefresh}
                            completedAt={
                              item.completed_at != null ? "false" : ""
                            }
                            ifAssigneMePost={
                              item?.assignee?.username ===
                              localStorage.getItem("username")
                                ? ""
                                : "false"
                            }
                            stateRefresh={refresh}
                            workflowColor={item?.current_workflow?.color}
                            currentWorkflow={item?.current_workflow?.stages}
                            background={
                              item?.assignee === null
                                ? "ticket22"
                                : item.completed_at != null
                                ? "ticketEnd"
                                : "ticketInProgress"
                            }
                            state={item.current_stage}
                            createdBy={
                              item?.created_by?.full_name.length === 0
                                ? item.created_by.username
                                : item?.created_by?.full_name
                            }
                            ownerGroup={item?.owner_group?.name}
                            usernameID={usernameID}
                            category={item?.current_category?.name}
                            icraci={item.assignee ? "icraçı" : ""}
                            assigned={
                              item.assignee
                                ? item?.assignee?.full_name.length === 0
                                  ? item?.assignee?.username
                                  : item?.assignee?.full_name
                                : "İcraçı təyin edilməyib"
                            }
                            date={datee}
                            time={formattedTime}
                            dateCompleted={
                              item.completed_at === null
                                ? ""
                                : `Tamamlandı:${dateCompleted}`
                            }
                            timeCompleted={
                              item.completed_at === null ? "" : formattedTime2
                            }
                            phoneNum={item.phone_number}
                            cashier_id={item?.cashier_id}
                            voen={item?.voen}
                          />
                        );
                      })
                    : null}

                  <div className={paginition}>
                    <Button
                      onClick={() => {
                        getTickets(1);
                      }}
                      variant={firstPagClass}
                    >
                      1
                    </Button>
                    <Button
                      onClick={() => getTickets(prevPage)}
                      variant="secondary"
                      className={prevClass}
                    >
                      {prevPage !== null ? prevPage : "done"}
                    </Button>
                    <Button
                      className={currentPageClass}
                      variant={currentPagVariant}
                    >
                      {currentPage}
                    </Button>
                    <Button
                      onClick={() => getTickets(nextPage)}
                      variant="secondary"
                      className={nextClass}
                    >
                      {nextPage !== null ? nextPage : "done"}
                    </Button>
                    <Button
                      className={totalPageClass}
                      onClick={() => {
                        getTickets(lastPage);
                      }}
                      variant={lastPagClass}
                    >
                      {lastPage}
                    </Button>
                    <Button variant="threeDotsBtn"></Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboardRight">
              <div className="newTicketTitleDashboard">
                <div className="newTicketR">
                  <Pin />
                  <span>Yeni tiket</span>
                </div>
                <span>{currentDate}</span>
              </div>
              <hr className="newTicketHR" />

              <div className="flex gap-4 flex-col">
                <div className="newTicketSelectDash">
                  <div className="mb-1">
                    <Label>
                      Müraciətin növü{" "}
                      <span className="text-amber-900 text-sm font-medium leading-5">
                        *
                      </span>
                    </Label>
                  </div>
                  <Dialog defaultOpen={popoverOpen}>
                    <DialogTrigger asChild>
                      <div className="categoriesOpener">
                        <span>{ticketCategoriesPlaceholder}</span>
                        <CategoriesArrow />
                      </div>
                    </DialogTrigger>
                    <DialogContent className="categorieContent">
                      <div className="categorieTitle">
                        <span>Mövzu seçin</span>
                        <div className="categorieTT">
                          <Input
                            placeholder="#2 Token"
                            onChange={(event) => {
                              setQuery(event.target.value);
                              setDependenceForDebounce(true);
                            }}
                            id="name"
                            name="search"
                            className="w-96 px-2 py-3"
                          />
                          <Select
                            name="current_category"
                            onValueChange={(e) => {
                              getCategories(e);
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="" />
                            </SelectTrigger>
                            <SelectContent className="overflow-scroll h-64">
                              <SelectGroup>
                                <SelectItem>Bütün qruplar</SelectItem>
                                {groupsData.map((item) => {
                                  return (
                                    <SelectItem key={item.id} value={item.id}>
                                      {item.name}
                                    </SelectItem>
                                  );
                                })}
                              </SelectGroup>
                            </SelectContent>
                          </Select>

                          <Select
                            name="current_category"
                            // value={newTicketCategories}
                            onValueChange={(e) => {
                              setSelectedKeywordData(e);
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Keyword seçin" />
                            </SelectTrigger>
                            <SelectContent className="overflow-auto h-64">
                              <SelectGroup>
                                {keywordsData.map((item) => {
                                  return (
                                    <SelectItem key={item.id} value={item.id}>
                                      {item.name}
                                    </SelectItem>
                                  );
                                })}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>

                      <div className="categoriesArea">
                        {dataCategories.map((item) => {
                          return (
                            <>
                              <DialogClose className="firstCategorieCard">
                                <div
                                  key={item.id}
                                  className="categorieCard"
                                  style={{
                                    background: `${item.color}`,
                                  }}
                                  onClick={() => {
                                    setQuery("");
                                    setNewTicketCategories(item.id);
                                    setTicketCategoriesPlaceholder(item.name);
                                    setPopoverOpen(false);
                                    if (
                                      item?.request_type?.fields === undefined
                                    ) {
                                      setTicketData([]);
                                      setInitialFormData([]);
                                      setRequestTypeData([]);
                                    } else {
                                      setRequestTypeData(
                                        item.request_type.fields
                                      );
                                      setTicketData(
                                        item.request_type.fields.map(
                                          (field) => ({
                                            field_definition: field.id,
                                            value: "",
                                          })
                                        )
                                      );
                                      setInitialFormData(
                                        item.request_type.fields.map(
                                          (field) => ({
                                            field_definition: field.id,
                                            value: "",
                                          })
                                        )
                                      );
                                    }
                                  }}
                                >
                                  <span>{item.name}</span>
                                  <p>{item.description}</p>
                                  <hr />
                                  <div className="cardWorkflowArea">
                                    {item.workflows?.map((item) => {
                                      return (
                                        <div
                                          key={item.id}
                                          className="cardWorkflow"
                                        >
                                          {item.name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </DialogClose>
                              <div className="secondCategorieCard">
                                <div
                                  key={item.id}
                                  className="categorieCard"
                                  style={{
                                    background: `${item.color}`,
                                  }}
                                >
                                  <div className="secondCategorieCardTitle">
                                    <DialogClose
                                      onClick={() => {
                                        setQuery("");
                                        setNewTicketCategories(item.id);
                                        setTicketCategoriesPlaceholder(
                                          item.name
                                        );
                                        setPopoverOpen(false);
                                        if (
                                          item?.request_type?.fields ===
                                          undefined
                                        ) {
                                          setTicketData([]);
                                          setInitialFormData([]);
                                          setRequestTypeData([]);
                                        } else {
                                          setRequestTypeData(
                                            item.request_type.fields
                                          );
                                          setTicketData(
                                            item.request_type.fields.map(
                                              (field) => ({
                                                field_definition: field.id,
                                                value: "",
                                              })
                                            )
                                          );
                                          setInitialFormData(
                                            item.request_type.fields.map(
                                              (field) => ({
                                                field_definition: field.id,
                                                value: "",
                                              })
                                            )
                                          );
                                        }
                                      }}
                                      className="flex items-center gap-3"
                                    >
                                      <CircleForCategorie />
                                      <span>{item.name}</span>
                                    </DialogClose>
                                    <span
                                      onClick={() => {
                                        categorieArrows === item.id
                                          ? setCategorieArrows("")
                                          : setCategorieArrows(item.id);
                                      }}
                                    >
                                      {categorieArrows !== item.id ? (
                                        <ArrowDown className="cursor-pointer" />
                                      ) : (
                                        <ArrowUp className="cursor-pointer" />
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display:
                                        categorieArrows !== item.id
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    <p>{item.description}</p>
                                    <hr className="secondCategorieCardHR" />
                                    <div className="cardWorkflowArea">
                                      {item.workflows?.map((item) => {
                                        return (
                                          <div
                                            key={item.id}
                                            className="cardWorkflow"
                                          >
                                            {item.name}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>

                {requestTypeData.map((item, index) => {
                  return (
                    <div className="newTicketInpBoxDash">
                      <div className="mb-1">
                        <Label>
                          {item?.display_name}
                          <span
                            className={item.required === false ? "false" : ""}
                          >
                            <span className="text-amber-900 text-sm font-medium leading-5">
                              *
                            </span>
                          </span>
                        </Label>
                      </div>
                      <div
                        className={
                          item.searchable === true ? "inputSearch" : ""
                        }
                      >
                        <Input
                          onChange={(e) => {
                            if (item.searchable === true) {
                              handleInputChange(
                                item.filter_with,
                                e.target.value
                              );
                            }
                            handleChange(e, item.id);
                          }}
                          value={ticketData[index].value}
                          placeholder="000 000"
                          onKeyDown={(e) => searchWithEnter(e)}
                          type={
                            item?.backend_field_type === "IntegerField"
                              ? "number"
                              : "text"
                          }
                        />
                        <div
                          onClick={() => {
                            getTickets();
                          }}
                          className={
                            item.searchable === true
                              ? "dashboardSearch"
                              : "false"
                          }
                        >
                          <SearchI />
                        </div>
                      </div>
                    </div>
                  );
                })}

                <TextareaAutosize
                  onChange={(e) => setDescriptionValue(e.target.value)}
                  value={descriptionValue}
                  name="description"
                  id="message"
                  className="w-full text-sm font-normal font-sans leading-normal p-3 rounded-lg  border border-solid    focus:border-stone-950 bg-white dark:bg-slate-900 text-slate-900 dark:text-slate-300 focus-visible:outline-3 box-border"
                  aria-label="empty textarea"
                  placeholder="Mətni daxil edin."
                  minRows={3}
                  maxRows={10}
                />
              </div>

              <div className="flex items-center space-x-2 mt-3">
                <Checkbox
                  checked={isInitialAssignee}
                  onCheckedChange={(checked) => {
                    setIsInitialAssignee(checked);
                    assigneeForTicketPost.assignee = checked ? usernameID : "";
                  }}
                  id="terms"
                />
                <label
                  htmlFor="terms"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Mənə təyin et
                </label>
              </div>
              <Button
                onClick={() => {
                  setButtonDisabled(true);
                  ticketPost();
                  setPaginition("false");
                }}
                type="submit"
                variant="mybtn"
                width="400px"
                disabled={buttonDisabled}
              >
                <span className="newTicketText">Göndər</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
