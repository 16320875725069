import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { Button } from "../components/ui/button";
import { DialogCloseButton } from "../component/Alert";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../components/ui/dialog";
import { useReactToPrint } from "react-to-print";
import { MiniClose } from "../svg";

const ClientSubmitForm = ({ setUser }) => {
  const token = localStorage.getItem("access");
  const URL = process.env.REACT_APP_ENVIRONMENT;

  const [newTicketCategories, setNewTicketCategories] = useState("");
  const [dataCategories, setDataCategories] = useState([]);
  const [ticketPostRes, setTicketPostRes] = useState([]);

  const [error, setError] = useState({
    open: false,
    title: null,
    description: null,
    success: null,
  });

  const dates = new Date();
  let days = dates.getDate();
  let months = dates.getMonth() + 1;
  let years = dates.getFullYear();
  let currentDate = `${days}/${months}/${years}`;

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const ticketPost = () => {
    axios
      .post(
        `${URL}/api/tickets/`,
        {
          current_category: newTicketCategories,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        setTicketPostRes(res.data.data);
        setTimeout(() => {
          handlePrint();
        }, 500);
        setError({
          open: true,
          title: "Successfully accepted!",
          description: "Müraciət uğurla yaradıldı",
          success: res?.data.success,
        });
        handlePrint();
        setNewTicketCategories("");
      })
      .catch((res) => {
        if (res?.response?.status === 400) {
          setError({
            open: true,
            title: "Error message",
            description: res?.response?.data?.data?.error,
            success: res?.response?.data?.success,
          });
        }
      });
  };

  const getCategories = () => {
    axios(`${URL}/api/categories/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page_size: 9999999,
        keyword: "clientSubmit",
        ordering: "name",
      },
    })
      .then((res) => {
        setDataCategories(res.data.data.results);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div>
        {error.open ? (
          <DialogCloseButton setError={setError} error={error} />
        ) : null}
        <div>
          <div className="clientSubmitFormTitle">
            <div className="clientSubmitFormTitleL"></div>
            <div className="clientSubmitFormTitleR">
              <h2>Kategoriya seçin</h2>
              <span>{currentDate}</span>
            </div>
          </div>
          <div className="clientSubmitForm">
            {dataCategories.map((item) => {
              return (
                <Dialog key={item.id}>
                  <DialogTrigger>
                    <div className="firstCategorieCard">
                      <div
                        className="categorieCard"
                        style={{
                          background: `${item.color}`,
                        }}
                        onClick={() => {
                          setNewTicketCategories(item.id);
                        }}
                      >
                        <span>{item.name}</span>
                        <p>{item.description}</p>
                        <hr />
                      </div>
                    </div>
                  </DialogTrigger>
                  <DialogContent className="dialogContentFirstPopUp">
                    <DialogClose className="firstPopUpCloseBtn">
                      <MiniClose />
                    </DialogClose>
                    <div className="firstPopUpForClient">
                      <h2 className="text-center">
                        Müraciəti yaratmaq istədiyinizdən əminsinizmi?
                      </h2>
                      {/* <p>
                        <span>Kategoriyanin adi:</span> Aisino ile bagli
                        muraciet
                      </p> */}
                      <DialogClose
                        onClick={() => {
                          ticketPost();
                        }}
                      >
                        <Button>Yarat</Button>
                      </DialogClose>
                    </div>
                  </DialogContent>
                </Dialog>
              );
            })}
          </div>

          {/* AFTER HERE */}
          <div
            style={{
              display: "none",
            }}
          >
            <div
              ref={printRef}
              style={{
                width: "100%",
                height: "70mm",
                padding: "10mm 10mm 10mm 10mm",
                borderBottom: "1px solid black",
              }}
            >
              <h2
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Müraciətin nömrəsi:
              </h2>
              <span
                style={{
                  fontSize: "40px",
                  paddingLeft: "20px",
                  fontWeight: "bolder",
                }}
              >
                {ticketPostRes?.id}
              </span>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {ticketPostRes?.current_category?.name}
              </p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Omnitech
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientSubmitForm;
