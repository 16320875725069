import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { RedFlag } from "../svg";

const Container = styled.div`
  box-shadow: 5px 5px 5px 2px grey;
  color: #000;
  margin-bottom: 8px;
  height: 230px;
  width: 279px;
  background-color: ${(props) => bgcolorChange(props)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

function bgcolorChange(props) {
  return props.isDragging
    ? "lightgreen"
    : props.isDraggable
    ? props.isBacklog
      ? "#F2D7D5"
      : "#DCDCDC"
    : props.isBacklog
    ? "#85E0A3"
    : "#85E0A3";
}

export default function DragAndDropCard({ task, index }) {
  const datee = new Date(task.created_at).toLocaleDateString();
  const dateee = new Date(task.created_at);
  const hours = dateee.getHours();
  const minutes = dateee.getMinutes();
  const seconds = dateee.getSeconds();
  const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  const date = `${datee} ${formattedTime}`;
  return (
    <Draggable draggableId={`${task.id}`} key={task.id} index={index}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <div className="dragAndDropCard">
            <div className="dragAndDropCardTitle">
              <div>
                <RedFlag /> <span>{task?.current_category?.name}</span>
              </div>
              <p>Yaradıldı: {date}</p>
              <h2>{task?.owner_group?.name}</h2>
            </div>
            <div className="dragAndDropCardCustomerData">
              <div>
                <span>{task?.created_by?.username}</span>
                <span>#{task?.id}</span>
              </div>
            </div>
            <hr className="dragAndDropCardHr" />
            <div className="dragAndDropCardEnd">
              <div className="dragAndDropCardEndLeft">
                <p>{task?.assignee?.username}</p>
                <span>İcraçı</span>
              </div>
              <div className="dragAndDropCardEndRight">
                <p
                  style={{
                    backgroundColor:
                      task?.current_stage?.label === "Start"
                        ? "#2563EB"
                        : task?.current_stage?.label === "in progress"
                        ? "#FEF08A"
                        : task?.current_stage?.label === "done"
                        ? "#059669"
                        : "#2563EB",
                  }}
                  className="stageCircle"
                ></p>
                <h2>{task?.current_stage?.label}</h2>
              </div>
            </div>
          </div>
          {provided.placeholder}
        </Container>
      )}
    </Draggable>
  );
}
